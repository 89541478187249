<template>
    <div>
        <div>
            <van-search v-model="keyword" placeholder="请输入班级名称" />
        </div>
        <div class="classes-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <div class="classes-list-item" v-for="(item,index) in list" :key="index">
                    <!-- <h1>{{item.class_name}}</h1>
                    <div>学员人数：{{item.class_member_count}}人</div>
                    <div>所属校区：{{item.studio_name}}</div>
                    <div class="classes-list-btn">
                        <div>
                            <van-icon name="comment-o" />
                            <span>发布动态</span>
                        </div>
                        <div>
                            <van-icon name="friends-o" />
                            <span>小组学员</span>
                        </div>
                        <div>
                            <van-icon name="records" />
                            <span>布置作业</span>
                        </div>
                    </div> -->

                    <van-cell-group>
                        <van-cell>
                            <template #title>
                                <span>{{item.class_name}}</span>
                            </template>
                            <template #label>
                                <van-tag plain type="success">{{`${item.class_member_count}`}}人</van-tag>
                                <van-tag plain type="success" style="margin-left:5px;">{{`${item.tutor_id}`}}</van-tag>
                                <!-- <span class="custom-title">{{`${item.studio_name}`}}</span> -->
                            </template>
                        </van-cell>
                    </van-cell-group>


                </div>
            </van-list>
            <van-empty v-if="list.length == 0 && !searchLoading" description="暂无相关数据~" />
        </div>
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
    import { Search, Empty, Cell, CellGroup, Tag } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'classes',
        data() {
            return {
                keyword: '',
                loading:false,
                finished: false,
                searchLoading: false,
                list:[],
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
            }
        },
        components: {
            [Search.name]: Search,
            [Empty.name]: Empty,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tag.name]: Tag,
            loadings:Loadings,
        },
        methods: {
            getList(){
                this.loading = true
                this.searchLoading = true
                this.$api.profile_classes({ 'per-page': this.pageSize, page: this.pageCurrent, class_name:this.keyword})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getList()
            },
        },
        watch: {
            keyword() {
                this.searchLoading = true
                this.pageCurrent = 1
                this.getList()
            }
        },
    }
</script>

<style lang="less" scoped>
    .classes-list{
        &-item{
            // width: 90%;
            // margin:0 auto;
            // background: #fff;
            // border-radius: 5px;
            margin-top: 10px;
            // padding: 10px 10px 0 10px;
            // box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
            h1{
                font-size: 16px;
                
            }
        }
        &-btn{
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-top: 1px solid #e8e8e8;
            margin-top: 10px;
            div{
                display: flex;
                align-items: center;
                padding: 10px;
                span{
                    margin-left: 5px;
                }
            }
        }
    }
</style>

<style lang="less">
    .classes-list-item{
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        .van-cell__title{
            flex: 2;
        }
    }
</style>